import * as React from 'react';
import PageSection from '../PageSection';
import Hero from './Hero';
import Partnership from './Partnership';
import Outcome from './Outcome';
import Buttons from './Buttons';
import DocumentHead from '../DocumentHead';
import { PartnershipCaseStudyData } from '../../types/content/caseStudies';

type BodyPropsType = {
  partnershipCaseStudyData: PartnershipCaseStudyData;
};

const PartnershipPage = ({ partnershipCaseStudyData }: BodyPropsType) => {
  return (
    <div data-testid="case-study-container" className="md-900px:pt-9 pt-7">
      <DocumentHead content={partnershipCaseStudyData.metadata} />
      <PageSection
        testId="hero"
        className="mb-7 md:mb-10 max:mx-68px"
        justifyClass="md:flex md:justify-start max:justify-center"
      >
        <Hero
          detailsData={partnershipCaseStudyData.details}
          heroData={partnershipCaseStudyData.hero}
        />
      </PageSection>
      <PageSection
        testId="partnership-container"
        className="mx-3 lg:mx-68px md:mx-64px mb-7 md:mb-10"
      >
        <Partnership partnerShipData={partnershipCaseStudyData.partnership} />
      </PageSection>
      <PageSection
        testId="outcome-container"
        className="mb-10 md:mb-12 mx-3 lg:mx-68px md:mx-64px"
      >
        <Outcome
          titleClassName="font-bold md:font-extrabold text-2xl md:font-extrabold leading-8 md:leading-12 md:text-32px leading-9 mb-2.5 md:mb-3"
          outcomeData={partnershipCaseStudyData.outcome}
        />
      </PageSection>
      <PageSection
        testId="buttons-container"
        className="mx-3 lg:mx-68px md:mx-64px"
      >
        <Buttons ctas={partnershipCaseStudyData.ctas} />
      </PageSection>
    </div>
  );
};

export default PartnershipPage;
