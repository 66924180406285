import React from 'react';
import Image from '../Image';
import { PartnershipData } from '../../types/content/caseStudies';

type BodyPropsType = {
  partnerShipData: PartnershipData;
};

const Partnership = ({ partnerShipData }: BodyPropsType) => {
  return (
    <div className="lg:flex lg:flex-col">
      <div className="md:flex md:flex-col">
        <div data-testid="partnership-content-container" className="md:w-8/12">
          <h1
            data-testid="partnership-header"
            className="font-bold text-2xl  
            md:font-extrabold md:leading-12 md:text-32px mb-2.5 md:mb-3 md:my-0"
          >
            {partnerShipData.title}
          </h1>
        </div>
        <p
          data-testid="partnership-description"
          className="font-normal text-base mb-7 md:mb-0 lg:text-lg mb-7 md:mb-10 md:w-9/12 "
        >
          {partnerShipData.description}
        </p>
      </div>
      <div
        data-testid="partnership-description-image"
        className="w-full aspect-w-16 aspect-h-9 overflow-hidden"
      >
        <Image
          className="w-full h-full object-cover rounded"
          src={`../../casestudy/${partnerShipData.image.file}`}
          webpSrc={`../../casestudy/${partnerShipData.image.webpFile}`}
          alt={`${partnerShipData.image.alt}`}
        />
      </div>
    </div>
  );
};

export default Partnership;
